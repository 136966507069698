<template>
	<div class="page-not-found">
		<img :src="logoUrl" @click="toHome" />
		<div class="wrapper">
			<i class="fas fa-exclamation-triangle fa-6x icon"></i>
			<h1>404 Not Found</h1>
			<h2>페이지를 찾을 수 없습니다.</h2>
			<button class="btn--md btn--fill-accent" @click="toHome">홈으로</button>
		</div>
	</div>
</template>

<script>
import logoUrl from '@/assets/images/new-fctslogo.png';
export default {
	data() {
		return {
			logoUrl,
		};
	},
	methods: {
		toHome() {
			this.$router.push('/');
		},
	},
};
</script>

<style></style>
